<template lang="pug">
.component
  ProductComplianceCharts(
    v-for="chart in typeCharts"
    :key="chart"
    :response="response"
    :isLoading="isLoading"
    :group="group"
    :activeGroup="activeGroup"
    :activeTab="activeTab"
    :label="chartLabels[chart]"
    :typeChart="chart"
  )
</template>

<script>
import ProductComplianceCharts from '@/components/Nestle/ProductComplianceCharts'

export default {
	name: "ProductCompliance",
	components: {
		ProductComplianceCharts,
	},
	props: {
		response: {
			type: Object,
			default: () => {},
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		group: {
			type: String,
			default: 'Store',
		},
		activeGroup: {
			type: String,
			default: 'stores',
		},
		activeTab: {
			type: String,
			default: 'Sku',
		},
	},
	data() {
		return {
			typeCharts: ['brandInCard', 'keyWords', 'descriptionLength'],
			chartLabels: {
				brandInCard: 'Presence of a brand in the card name',
				keyWords: 'Presence of key words in the description',
				descriptionLength: 'Minimum description lenght',
			}
		}
	},
}
</script>

<style lang="scss" scoped>
</style>
