<template lang="pug">
.component
  ContainerForData.mt-24(width="100%" :isLoading="isLoading")
    template(#header-left)
      .flex.items-center
        p {{ label }}
          span.color-percent(:class="'color-' + roundUpToNearestTen(getPercent)") {{ getPercent }}%
        Tooltip.ml-8(
          v-if="false"
          trigger="hover"
        )
          template(slot="reference")
            HelpCircle.icon
          span 'Tooltip'
    template(#data)
      BarChart(
        ref="bar"
        :key="isTypePercent"
        :chartData="stackedBarChartData"
        :options="stackedBarChartOptions"
        :style="{'height': `${180 + (40 * (lengthOfResult - 1)) }px`}"
      )
</template>

<script>
import Tooltip from '@/components/Elements/Tooltip.vue'
import Tabs from "@/components/Nestle/Tabs"
import ContainerForData from "@/components/Nestle/ContainerForData"
import BarChart from "@/components/Chart/BarChart"
import HelpCircle from "vue-material-design-icons/HelpCircle.vue"

export default {
  name: "ProductCompliance",
  components: {
    Tabs,
    Tooltip,
    ContainerForData,
    BarChart,
    HelpCircle,
  },
  props: {
    response: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: 'Store',
    },
    activeGroup: {
      type: String,
      default: 'stores',
    },
    activeTab: {
      type: String,
      default: 'Sku',
    },
    label: {
      type: String,
      required: true,
    },
    typeChart: {
      type: String,
      required: true,
    }

  },
  data() {
    return {
      colors: {
        0: "#B8222B",
        10: "#CF4308",
        20: "#E4592D",
        30: "#FF6802",
        40: "#FA8231",
        50: "#F79C31",
        60: "#F7B731",
        70: "#D3D719",
        80: "#49A22F",
        90: "#57C026",
        100: "#5EDB24",
      },
    }
  },
  computed:{
    getPercent() {
      if (!this.response.productCompliance) return ''
      return this.response.productCompliance[this.activeGroup][this.typeChart].percent;
    },
    lengthOfResult() {
      if (!this.response.productCompliance[this.activeGroup][this.typeChart].values) return
      return this.response.productCompliance[this.activeGroup][this.typeChart].values.length
    },
    isTypePercent() {
      return this.activeTab === 'Percent'
    },
    datasetsValues() {
      return this.isTypePercent ? this.percentStores : this.compliantStoreSku;
    },
    roundUpToNearestTen: () => (num) => {
      return `${Math.round(num / 10) * 10}`;
    },
    compliantStoreSku() {
     return [
          {
            label: 'Complaint',
            borderColor: '#49A22F',
            backgroundColor: '#49A22F',
            data: this.response.productCompliance[this.activeGroup][this.typeChart].values.map(item => item.compliantSku),
            minBarLength: 2,
            borderRadius: Number.MAX_VALUE,
          },
          {
            label: 'Non-complaint',
            borderColor: '#E55627',
            backgroundColor: '#E55627',
            data: this.response.productCompliance[this.activeGroup][this.typeChart].values.map(item => item.noCompliantSku),
            borderRadius: Number.MAX_VALUE,
          },
        ]
    },
    percentStores() {
     return [
          {
            borderColor: this.response.productCompliance[this.activeGroup][this.typeChart].values.map(item => this.colors[this.roundUpToNearestTen(item.percent)]),
            backgroundColor: this.response.productCompliance[this.activeGroup][this.typeChart].values.map(item => this.colors[this.roundUpToNearestTen(item.percent)]),
            data: this.response.productCompliance[this.activeGroup][this.typeChart].values.map(item => item.percent),
            minBarLength: 2,
            borderRadius: Number.MAX_VALUE,
          }
        ]
    },
    stackedBarChartOptions () {
      return {
        indexAxis: 'y',
        plugins: {
          responsive: true,
          datalabels: {
            display: true,
            formatter: (index) => {
              if (!index) return  ''
              return `${index}${this.isTypePercent ? '%' : ''}`
            },
            color: 'white',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            font: {
              weight: 'bold',
              size: 14
            }
          },
          tooltip: {
            mode: 'dataset',
            enabled: !this.isTypePercent,
            displayColors: false,
            callbacks: {
              title: () => '',
              label: (context) => {
                const index = context.dataIndex;
                const values = this.response.productCompliance[this.activeGroup][this.typeChart].values;
                const name = values[index].name;
                const percent = values[index].percent;
                const result = `${name}: ${percent}%`

                return result
              },
            },
          },
          legend: {
            display: !this.isTypePercent,
            position: 'bottom',
            labels: {
              font: {
                size: 14
              }
            }
          },
          title: {
            position: 'bottom',
            display: !this.isTypePercent,
            text: this.label,
            font: {
              size: 18
            }
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
						max: this.isTypePercent ? 100 : undefined,
            ticks: {
              maxTicksLimit: 50,
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: this.isTypePercent ? '#424341' : '#9EADC0',
            },
            border: {
              display: true,
              color: 'red',
              width: 10
            }
          }
        }
      }
    },
    stackedBarChartData() {
      if (!this.response && !this.response.productCompliance[this.activeGroup][this.typeChart].values) return
      const postfix = this.activeTab === 'Percent' ? '%' : ''

      return {
        labels: this.response.productCompliance[this.activeGroup][this.typeChart].values.map((item) => `${item.name} ${postfix ?  item.percent + postfix : ''}`),
        datasets: this.datasetsValues,
      }
    },
  },

}
</script>

<style lang="scss" scoped>
::v-deep.element-container__title-right {
  height: 40px;
}

::v-deep.chart {
  width: 100%;
}

select{
  padding: 0 5px;
  outline: 1px color(gray-400) solid;
  border-radius: 3px;
  color: color(gray-700);

  &:focus{
    outline: none;
  }
}

.component {
  max-width: 1280px;
  margin: 30px auto 0;
}

.color {
  &-percent {
    display: inline-block;
    padding: 5px 15px;
    margin-left: 16px;
    border-radius: 52px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    color: #FFFFFF;
  }
  &-0 {
    background-color: #B8222B;
  }
  &-10 {
    background-color: #CF4308;
  }
  &-20 {
    background-color: #E4592D;
  }
  &-30 {
    background-color: #FF6802;
  }
  &-40 {
    background-color: #FA8231;
  }
  &-50 {
    background-color: #F79C31;
  }
  &-60 {
    background-color: #F7B731;
  }
  &-70 {
    background-color: #D3D719;
  }
  &-80 {
    background-color: #49A22F;
  }
  &-90 {
    background-color: #57C026;
  }
  &-100 {
    background-color: #5EDB24;
  }
}
</style>
